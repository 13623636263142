import React from 'react';
import { Container } from 'react-bootstrap';
import { useEffect, useState } from 'react';
export default function MainPage() {
    const [nums, setNums] = useState([]);
    const [dens, setDens] = useState([]);
    const [ans, setAns] = useState(0);
    const [myAns, setMyAns] = useState([0, 0]);
    const [submitted, setSubmitted] = useState(false);
    const [correct, setCorrect] = useState("");
    const [error, setError] = useState(0);
    useEffect(() => {
        generateQuestion();
    }, []);

    const randomInt = (start, end) => {
        return start + Math.floor((end - start + 1) * Math.random())
    }

    const randomNormalFloat = () => {
        let full_num = randomInt(100, 99999);
        let num_decimals = Math.pow(10, randomInt(2, 4))
        return [full_num / num_decimals, 0]
    }

    const tenRaiseToFloat = () => {
        let full_num = randomInt(0, 9) + Math.random();
        let num_precision = randomInt(1, 4);
        let power = randomInt(-50, 50);
        return [full_num.toFixed(num_precision), power]
    }

    const generateQuestion = () => {
        let num_numerator = randomInt(2, 5);
        let num_denominator = randomInt(2, 5);

        let numerators = []
        let denominators = []

        for (let i = 0; i < num_numerator; i++) {
            if (Math.random() < 0.2) {
                numerators.push(tenRaiseToFloat());
            }
            else {
                numerators.push(randomNormalFloat())
            }
        }
        for (let i = 0; i < num_denominator; i++) {
            if (Math.random() < 0.2) {
                denominators.push(tenRaiseToFloat());
            }
            else {
                denominators.push(randomNormalFloat())
            }
        }

        let num_val = numerators.reduce((prev, current) => {
            return [prev[0] * current[0], prev[1] + current[1]]
        })

        let den_val = denominators.reduce((prev, current) => {
            return [prev[0] * current[0], prev[1] + current[1]]
        })

        let answer = num_val[0] / den_val[0] * Math.pow(10, num_val[1] - den_val[1])

        setAns(answer);
        setNums(numerators);
        setDens(denominators);
        setSubmitted(false);
    }

    const setNormal = (p) => {
        let string = p.target.value;
        if (string.length == 0 || !isNaN(parseFloat(string) || (string.length == 1 && string == "-"))) {
            const regex = /^-?\d*(\.\d+)?$/;
            if (regex.test(string) || regex.test(string.replace(".", ""))) {
                setMyAns([p.target.value, myAns[1]]);
            }
        }
    }

    const setPower = (p) => {
        let string = p.target.value;
        if (string.length == 0 || !isNaN(parseInt(string) || (string.length == 1 && string == "-"))) {
            setMyAns([myAns[0], p.target.value])
        }
    }

    const submitAnswer = () => {
        if (submitted) return;
        setSubmitted(true);
        let cor = ans.toExponential();
        let data = cor.split("e");

        let man = myAns[0];
        let exp = myAns[1]
        if (man.length == 0) man = 0;
        if (exp.length == 0) exp = 0;
        let full_ans = man * Math.pow(10, exp);
        const my_err = Math.abs(ans - full_ans) * 100 / ans;
        setError(Math.min(100, my_err));
        setCorrect([parseFloat(data[0]).toFixed(3), data[1]])
    }

    return (<div className='main-wrap'>
        <div className='main-header'>
            <Container>
                <h5 className='header-name'>Annachi Calculator</h5>
            </Container>
        </div>

        <div className='wrap'>
            <div className='inbox'>
                <h4>The Annachi Calculator</h4>
                <hr></hr>
                <div className='parenter' style={{height: '85%'}}>
                    Find the value of:<br></br>

                    <div className='encloser'>
                        {nums.map((p, i) => {

                            return (<span className='num-item'>
                                {(i == 0) ? (<></>) : (<span className='x-item'>x</span>)} {p[0]} {(p[1] == 0) ? (<></>) : (<><span className='x-item'>x</span> 10<sup>{p[1]}</sup></>)}
                            </span>)
                        })}
                        <hr></hr>
                        {dens.map((p, i) => {

                            return (<span className='num-item'>
                                {(i == 0) ? (<></>) : (<span className='x-item'>x</span>)} {p[0]} {(p[1] == 0) ? (<></>) : (<><span className='x-item'>x</span> 10<sup>{p[1]}</sup></>)}
                            </span>)
                        })}
                    </div>
                    <div className='toolbox'>
                        <div>
                            My answer:
                        </div>
                        <div style={{ marginTop: '1em' }} className='parenter'>
                            <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                <span style={{ width: 'fit-content' }}>
                                    <input onChange={(p) => setNormal(p)} value={myAns[0]} type="text" name="name" />
                                </span>
                                <span className='x-item'>x</span>
                                <span>
                                    10 <sup><input onChange={(p) => setPower(p)} value={myAns[1]} type="text" /></sup>
                                </span>
                            </div>

                        </div>
                        <div className='parenter'>
                            <div style={{marginLeft: 'auto', marginRight: 'auto', marginTop: '1.3em', textAlign: 'center'}}>
                                {submitted ? (<>Correct answer is {correct[0]} <span className='x-item' style={{paddingLeft: '3px', paddingRight: '3px'}}>x</span> 10 <sup>{correct[1]}</sup><br></br> Error rate: {error.toFixed(2)} %</>) : (<></>)}
                            </div>
                        </div>

                    </div>
                    <div style={{ width: '100%', marginTop: 'auto' }}>
                        <div onClick={() => submitAnswer()} style={{opacity: submitted? 0.4 : 1}} className='button'>
                            Submit
                        </div>

                        <div onClick={() => generateQuestion()} className='button'>
                            Next
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>);
}